import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={400} height={400} viewBox="0 0 300 300" {...props}>
      <g fillRule="evenodd">
        <path
          d="M50 0a101 101 0 00-21 5 70 70 0 00-9 5 60 60 0 00-10 10 41 41 0 00-7 12L1 44 0 150l1 106a54 54 0 006 20l2 2a79 79 0 008 10 59 59 0 0012 7 43 43 0 0010 3 54 54 0 007 1l15 1a4136 4136 0 01193-1 68 68 0 0017-4 43 43 0 0023-20 40 40 0 002-6l1-1a163 163 0 002-7l1-7a26993 26993 0 00-3-221 14 14 0 010-2l-1-1a34 34 0 00-3-7 40 40 0 00-4-5 47 47 0 00-23-16l-15-2H50m206 17c15 3 26 14 27 30v206c-1 16-13 27-29 29H45c-14-2-25-13-27-28V46a31 31 0 0131-29h207m-36 72v-1 1m-14 12h1-1m-94 53"
          fill="#f38304"
        />
        <path
          d="M205 76c-7 0-11 6-11 13s5 12 13 12c10 0 16-9 12-18-2-5-8-8-14-7m-100 3l-3 8-1 5-2 7-3 7-2 7-2 7-2 5-1 3a618 618 0 00-11 34l-2 9a839 839 0 00-12 37l23 1 4-12c8-26 8-25 9-25h40l2 4a3924 3924 0 0010 33h24c1-1 1 0-2-10l-2-5-1-4a432 432 0 01-8-26l-2-4a2300 2300 0 01-7-24l-2-6a2043 2043 0 00-17-51h-30m15 20a327 327 0 017 26l1 4 2 5 2 6a4126 4126 0 004 14h-33v-1a209 209 0 013-10l1-3a4037 4037 0 003-10l1-3 3-9 2-9c4-13 3-12 4-10m75 63v47h24v-94h-24v47"
          fill="#2c1c14"
        />
        <path
          d="M49 17h-2a31 31 0 00-29 28v209c2 15 13 26 27 28h209c16-2 28-13 29-29V47c-1-16-12-27-27-30H49m160 59c8 1 13 9 10 17-3 10-19 11-24 2-2-4-2-8 0-12 2-5 8-8 14-7m-74 3l2 5 2 6a1271 1271 0 013 8l1 4a10126 10126 0 006 20l1 4a2542 2542 0 0111 32l1 4 3 8 2 8 2 4 1 4 3 8 3 10 1 4-13 1h-12v-1l-3-7-1-6-2-5a1053 1053 0 00-6-18h-40l-3 5-4 14a13907 13907 0 00-6 18H64v-2a414 414 0 008-26l2-5a389 389 0 003-11l3-7a813 813 0 008-27l3-9 3-8 2-6a302 302 0 012-6l2-7 5-16h30m-16 19l-2 10-5 17-1 4-1 2a9175 9175 0 00-5 15l-1 4-1 4h33v-1l-5-16a520 520 0 01-12-39m100 64v47h-24v-94h24v47"
          fill="none"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
